import Guest from '@/Layouts/Guest';
import React, {useEffect} from 'react';
import ValidationErrors from '@/Components/ValidationErrors';
import {Head, useForm} from '@/util/Inertia';
import bgImage from '@/img/bg/climpek.png';
import {Button, Form} from 'react-bootstrap';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logo from "@/img/logo-2.png";

export default function Login({status, canResetPassword, appName}) {
    const {data, setData, post, processing, errors, reset} = useForm({
        email: '',
        password: '',
        remember: '',
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    return (
        <Guest>
            <Head title="Login"/>
            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}


            <div className="login login-v2">
                <div className="login-cover">
                    <div className="login-cover-bg"></div>
                    <div className="login-cover-img" style={{backgroundImage: `url(${bgImage})`}}></div>

                </div>
                <ValidationErrors errors={errors}/>
                <div className="login-container">
                    <div className="login-header">
                        <div className="brand">
                            <img src={logo}
                                 style={{maxWidth: '45px', marginRight: '10px'}} alt={appName}/>
                            {appName}
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faLock} size={'3x'}/>
                        </div>
                    </div>
                    <div className="login-content">
                        <form className="margin-bottom-0" onSubmit={submit}>
                            <Form.Group className={'mb-20px'}>
                                <Form.Control
                                    name="email"
                                    placeholder="email"
                                    value={data.email}
                                    className="form-control form-control-lg"
                                    autoComplete="username"
                                    onChange={onHandleChange}
                                />
                            </Form.Group>
                            <Form.Group className={'mb-20px'}>
                                <Form.Control
                                    name="password"
                                    type={'password'}
                                    placeholder="password"
                                    value={data.password}
                                    className="form-control form-control-lg"
                                    autoComplete="username"
                                    onChange={onHandleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <div className="d-grid gap-2">
                                    <Button type={'submit'} variant={'primary'} block>Sign me in</Button>
                                </div>
                            </Form.Group>


                        </form>
                    </div>
                </div>

            </div>
        </Guest>
    );
}
